<template>
  <!-- #site-wrapper start -->
  <div
    v-if="user"
    id="site-wrapper"
    class="site-wrapper explore-haft-map explore-haft-map-grid"
  >
    <!-- #header start -->
    <!-- #header start -->
    <NavBar />
    <!-- #header end -->
    <!-- #wrapper-content start -->
    <div id="wrapper-content" class="wrapper-content h-100">
      <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap h-100">
        <div class="page-container">
          <div class="container-fluid">
            <div class="widget widget-filter">
              <div class="form-filter">
                <form>
                  <div
                    class="
                      d-flex
                      flex-wrap flex-lg-nowrap
                      align-items-center
                      mb-4 mb-lg-2
                      filter-items
                    "
                  >
                    <div class="form-group category mb-3 mb-lg-0">
                      <router-link
                        to="/"
                        style="background: #36beb0; border-color: #36beb0"
                        class="
                          btn btn-primary
                          rounded-sm
                          text-white
                          letter-spacing-25
                          py-1
                          lh-1625
                          btn-icon-left
                          px-1
                          font-size-sm
                        "
                        active-class="active"
                        exact
                      >
                        <i class="fal fa-angle-left"></i>
                        Back to dashboard
                      </router-link>
                    </div>

                    <div class="mb-3 mb-lg-0 filter-item">
                      <!-- <a
                        href="javascript:void"
                        class="
                          btn btn-block
                          letter-spacing-25
                          py-1
                          lh-1625
                          btn-icon-left
                          px-1
                          font-size-sm
                        "
                        ><i class="fal fa-clock"></i>
                        Open Now
                      </a> -->

                      <a
                        href="javascript:void"
                        @click="getNearByRelatedPartners"
                        class="
                          btn btn-block
                          letter-spacing-25
                          py-1
                          lh-1625
                          btn-icon-left
                          px-1
                          font-size-sm
                        "
                        ><i class="fas fa-map-marker"></i>
                        Near by Related
                      </a>
                    </div>
                    <div class="mb-3 mb-lg-0 filter-item">
                      <a
                        href="javascript:void"
                        @click="getNearByPartners"
                        class="
                          btn btn-block
                          letter-spacing-25
                          py-1
                          lh-1625
                          btn-icon-left
                          px-1
                          font-size-sm
                        "
                        ><i class="fas fa-map-marker"></i> Near By
                        {{ user.activeBusiness.businessName }}
                      </a>
                    </div>
                    <!-- <div class="mb-3 mb-lg-0 filter-item pr-0">
                      <a
                        href="#"
                        class="
                          btn btn-block
                          letter-spacing-25
                          py-1
                          lh-1625
                          btn-icon-left
                          px-1
                          font-size-sm
                        "
                        ><i class="fal fa-comment"></i>
                        Most Reviewed
                      </a>
                    </div> -->
                  </div>
                  <div
                    class="
                      form-search form-search-style-02
                      pb-3
                      pt-3
                      fadeInDown
                      animated
                    "
                    data-animate="fadeInDown"
                  >
                    <form autocomplete="off">
                      <div class="row align-items-end no-gutters">
                        <div
                          class="
                            col-xl-5
                            mb-4 mb-xl-0
                            py-3
                            px-4
                            bg-white
                            border-left border-top border-bottom border-right
                            position-relative
                            rounded-left
                            form-search-item
                          "
                        >
                          <label
                            for="key-word"
                            class="
                              font-size-md font-weight-semibold
                              text-dark
                              mb-0
                              lh-1
                            "
                            >Find a business</label
                          >
                          <div class="input-group dropdown show">
                            <input
                              type="text"
                              autocomplete="off"
                              id="key-word"
                              name="key-word"
                              placeholder="Ex: food, service, barber, hotel"
                              class="
                                form-control form-control-mini
                                border-0
                                px-0
                                bg-transparent
                              "
                              v-on:keyup="filterAnything"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                            />
                            <a
                              class="input-group-append text-decoration-none"
                              data-toggle="dropdown"
                            >
                              <i class="fal fa-search"></i>
                            </a>
                            <!-- <ul
                              class="dropdown-menu form-search-ajax"
                              aria-labelledby="key-word"
                            > -->
                            <!-- <li class="dropdown-item item">
                                <a href="#" class="link-hover-dark-white">
                                  <svg class="icon icon-pizza">
                                    <use xlink:href="#icon-pizza"></use>
                                  </svg>
                                  <span class="font-size-md"
                                    >Foods &amp; Restaurants</span
                                  >
                                </a>
                              </li> -->

                            <!--                     
                            </ul> -->
                          </div>
                        </div>
                        <div
                          class="
                            col-xl-3
                            mb-4 mb-xl-0
                            py-3
                            px-4
                            bg-white
                            position-relative
                            rounded-right
                            form-search-item
                            bg-white
                            border-top border-bottom
                          "
                        >
                          <label
                            for="key-word"
                            class="
                              font-size-md font-weight-semibold
                              text-dark
                              mb-0
                              lh-1
                            "
                            >Where</label
                          >
                          <div class="input-group dropdown">
                            <input
                              type="text"
                              autocomplete="off"
                              id="countryName"
                              name="countryName"
                              v-on:keyup="filterCountries"
                              class="
                                form-control form-control-mini
                                border-0
                                px-0
                                bg-transparent
                              "
                              :placeholder="
                                this.currentCountry &&
                                this.currentCountry.countryName
                                  ? this.currentCountry.countryName
                                  : 'Find a country'
                              "
                              data-toggle="dropdown"
                              aria-haspopup="true"
                            />
                            <a
                              href="javascript:void"
                              class="input-group-append text-decoration-none"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              @click="refreshCountries()"
                            >
                              <i class="fal fa-chevron-down"></i>
                            </a>
                            <ul
                              class="dropdown-menu form-search-ajax"
                              aria-labelledby="countryName"
                              x-placement="bottom-start"
                              style="
                                position: absolute;
                                will-change: transform;
                                top: 0px;
                                left: 0px;
                                transform: translate3d(340px, 66px, 0px);
                              "
                            >
                              <li
                                class="dropdown-item item"
                                v-for="country in listOfCountries"
                                :key="country.id"
                                @click="
                                  refreshCountries(
                                    country.id,
                                    country.countryName
                                  )
                                "
                              >
                                <a
                                  href="javascript:void"
                                  class="link-hover-dark-white"
                                  @click="
                                    refreshCountries(
                                      country.id,
                                      country.countryName
                                    )
                                  "
                                >
                                  {{ country.countryName }}
                                </a>
                              </li>
                            </ul>
                            <ul
                              v-if="countries.length == 0"
                              class="dropdown-menu form-search-ajax"
                              aria-labelledby="countryName"
                              x-placement="bottom-start"
                              style="
                                position: absolute;
                                will-change: transform;
                                top: 0px;
                                left: 0px;
                                transform: translate3d(340px, 66px, 0px);
                              "
                            >
                              <li class="dropdown-item item">
                                <i class="fal fa-search"></i> no result found!
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div
                          class="
                            col-xl-2
                            mb-4 mb-xl-0
                            py-3
                            px-4
                            bg-white
                            position-relative
                            rounded-right
                            form-search-item
                            bg-white
                            border-top border-bottom
                          "
                        >
                          <label
                            for="key-word"
                            class="
                              font-size-md font-weight-semibold
                              text-dark
                              mb-0
                              lh-1
                            "
                          ></label>
                          <div class="input-group dropdown">
                            <input
                              type="text"
                              autocomplete="off"
                              id="region"
                              name="region"
                              class="
                                form-control form-control-mini
                                border-0
                                px-0
                                bg-transparent
                              "
                              :placeholder="
                                this.currentState && this.currentState.stateName
                                  ? this.currentState.stateName
                                  : 'Find a state'
                              "
                              v-on:keyup="filterStates"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                            />
                            <a
                              v-if="listOfStates.length > 0"
                              href="javascript:void"
                              class="input-group-append text-decoration-none"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              @click="refreshStates"
                            >
                              <i class="fal fa-chevron-down"></i>
                            </a>

                            <ul
                              v-if="listOfStates.length > 0"
                              class="dropdown-menu form-search-ajax"
                              aria-labelledby="region"
                              x-placement="bottom-start"
                              style="
                                position: absolute;
                                will-change: transform;
                                top: 0px;
                                left: 0px;
                                transform: translate3d(340px, 66px, 0px);
                              "
                            >
                              <li
                                class="dropdown-item item"
                                v-for="state in listOfStates"
                                :key="state.id"
                                @click="
                                  refreshStates(state.id, state.stateName)
                                "
                              >
                                <a
                                  href="javascript:void"
                                  class="link-hover-dark-white"
                                  @click="
                                    refreshStates(state.id, state.stateName)
                                  "
                                >
                                  {{ state.stateName }}
                                </a>
                              </li>
                            </ul>

                            <ul
                              v-if="listOfStates.length == 0"
                              class="dropdown-menu form-search-ajax"
                              aria-labelledby="region"
                              x-placement="bottom-start"
                              style="
                                position: absolute;
                                will-change: transform;
                                top: 0px;
                                left: 0px;
                                transform: translate3d(340px, 66px, 0px);
                              "
                            >
                              <li class="dropdown-item item">
                                <i class="fal fa-search"></i> no result found!
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div
                          class="
                            col-xl-2
                            mb-4 mb-xl-0
                            py-3
                            px-4
                            bg-white
                            position-relative
                            rounded-right
                            form-search-item
                            bg-white
                            border-top border-bottom border-right
                          "
                        >
                          <label
                            for="key-word"
                            class="
                              font-size-md font-weight-semibold
                              text-dark
                              mb-0
                              lh-1
                            "
                          ></label>
                          <div class="input-group dropdown">
                            <input
                              type="text"
                              autocomplete="off"
                              id="region"
                              name="region"
                              v-on:keyup="filterCities"
                              class="
                                form-control form-control-mini
                                border-0
                                px-0
                                bg-transparent
                              "
                              :placeholder="
                                this.currentCity && this.currentCity.cityName
                                  ? this.currentCity.cityName
                                  : 'Find a city'
                              "
                              data-toggle="dropdown"
                              aria-haspopup="true"
                            />
                            <a
                              v-if="listOfCities.length > 0"
                              href="javascript:void"
                              class="input-group-append text-decoration-none"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="fal fa-chevron-down"></i>
                            </a>
                            <ul
                              v-if="listOfCities.length > 0"
                              class="dropdown-menu form-search-ajax"
                              aria-labelledby="region"
                              x-placement="bottom-start"
                              style="
                                position: absolute;
                                will-change: transform;
                                top: 0px;
                                left: 0px;
                                transform: translate3d(340px, 66px, 0px);
                              "
                              @click="refreshCities"
                            >
                              <li
                                class="dropdown-item item"
                                v-for="item in listOfCities"
                                :key="item.id"
                                @click="refreshCities(item.id, item.cityName)"
                              >
                                <a
                                  href="#"
                                  class="link-hover-dark-white"
                                  @click="refreshCities(item.id, item.cityName)"
                                >
                                  {{ item.cityName }}
                                </a>
                              </li>
                            </ul>
                            <ul
                              v-if="listOfCities.length == 0"
                              class="dropdown-menu form-search-ajax"
                              aria-labelledby="region"
                              x-placement="bottom-start"
                              style="
                                position: absolute;
                                will-change: transform;
                                top: 0px;
                                left: 0px;
                                transform: translate3d(340px, 66px, 0px);
                              "
                            >
                              <li class="dropdown-item item">
                                <i class="fal fa-search"></i> no result found!
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="form-group filter-tags border-bottom">
                    <a
                      href="javascript:void"
                      class="form-label text-decoration-none"
                      >Filter by categories</a
                    >

                    <div class="row pt-3">
                      <div
                        class="col-md-3 m-1"
                        v-for="item in businessCategories"
                        :key="item.id"
                      >
                        <div class="lh-19">
                          <input
                            v-model="businessCategoryIds"
                            :value="item.id"
                            @click="filterByCategories()"
                            class=""
                            type="checkbox"
                            id="credit-card"
                          />
                          <label class="ml-2" for="credit-card">
                            {{ item.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div v-if="isLoading">
              <div class="card-deck justify-content-center align-items-center">
                <MoonLoader
                  :loading="true"
                  :color="'#36beb0'"
                  :size="60"
                  :sizeUnit="'px'"
                />
              </div>
            </div>

            <div class="page-content" v-if="!isLoading">
              <div class="explore-filter d-sm-flex align-items-center d-block">
                <div
                  class="
                    text-dark
                    font-weight-semibold font-size-md
                    mb-4 mb-lg-0
                  "
                >
                  {{ listOfBusiness.length }} Results found
                </div>
                <!-- <div class="form-filter d-flex align-items-center ml-auto">
                                    <div class="format-listing">
                                        <a href="explore-half-map-grid.html"><i class="fas fa-th"></i></a>
                                        <a href="#" class="active"><i class="fal fa-bars"></i></a>
                                    </div>
                                </div> -->
              </div>
              <div class="store-listing-style store-listing-style-01">
                <div
                  class="mb-7 border-bottom"
                  v-for="business in listOfBusiness"
                  :key="business.id"
                >
                  <div class="store media align-items-stretch bg-white">
                    <div
                      class="position-relative store-image"
                      :style="{
                        backgroundImage: `url(${
                          business.partnerImages.length > 0
                            ? business.partnerImages[0].imageUrl
                            : business.logo
                        })`,
                      }"
                    >
                      <div
                        class="
                          image-content
                          position-absolute
                          d-flex
                          align-items-center
                        "
                      >
                        <div class="content-left">
                          <div class="badge badge-primary">
                            {{ business.partnerCategory.description }}
                          </div>
                        </div>
                        <!-- <div class="content-right d-flex ml-auto show-link">
                                                    <a href="images/shop/full-shop-7.jpg" class="item viewing" data-toggle="tooltip" data-placement="top" title="" data-gtf-mfp="true" data-original-title="Quickview">
                                                        <svg class="icon icon-expand">
                                                            <use xlink:href="#icon-expand"></use>
                                                        </svg>
                                                    </a>
                                                    <a href="#" class="item marking" data-toggle="tooltip" data-placement="top" title="" data-original-title="Bookmark"><i class="fal fa-bookmark"></i></a>
                                                </div> -->
                      </div>
                    </div>
                    <div class="media-body px-0 px-md-4 pt-4 pt-lg-0">
                      <div class="row mb-3">
                          <div class="col-9">
                      <a
                        href="javascript:void"
                        class="h5 text-dark"
                        ><span class="letter-spacing-25">
                          {{ business.businessName }}</span
                        >
                      </a> 
                      </div>
                    
                      
                      <div class="col-3 float-right text-right">
                        <span class="badge badge-success  mr-1"
                            >5.0</span
                          ><span>4 rating</span>
                    
                      </div>
                        </div>
                      <div class="media">
                        <div class="media-body lh-14 font-size-sm">
                          {{ business.description | truncate(500, "....") }}
                        </div>
                      </div>
                      <div
                        class="border-top pt-3 d-flex mt-4"
                        v-if="business.partnerAddresses.length > 0"
                      >
                        <div class="pr-3">
                          <span class="d-inline-block mr-1"
                            ><i class="fal fa-map-marker-alt"></i>
                          </span>
                          <a
                            href="#"
                            class="text-secondary text-decoration-none address"
                          >
                            {{ business.partnerAddresses[0].address }}
                          </a>
                          <br>
                          <span class="text-green">
                            {{ business.partnerAddresses[0].grideCode }}</span
                          >
                        </div>
                        <div class="ml-auto">
                          <a
                            href="javascript:void"
                            class="
                              link-hover-secondary-primary
                              btn btn-primary btn-sm
                              text-white
                            "
                          >
                            Request
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="p-3"
                v-if="!isLoading && this.listOfBusiness.length == 0"
              >
                No business Found!
              </div>
              <!-- <button
              class="btn btn-md btn-block mt-4"
              @click="loadMore()"
              v-if="currentPage * maxPerPage < this.listOfBusiness.length"
            >
              Load more
            </button> -->
            </div>
          </div>
          <FooterBar />
        </div>

        <div class="map" v-if="listOfBusiness.length > 0">
          <DisplayBusinessOnMap
            v-bind:businesses="listOfBusiness"
            v-bind:locations="currentAddress"
          />
        </div>
      </div>
    </div>

    <!-- #wrapper-content end -->
  </div>

  <div v-else>
    <LoadingScreen />
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
import NavBar from "../Layouts/NavBar";
import FooterBar from "../Layouts/FooterBar";
import LoadingScreen from "../Layouts/LoadingScreen";
import DisplayBusinessOnMap from "../MyBusiness/Section/DisplayBusinessOnMap";
import { MoonLoader } from "@saeris/vue-spinners";

export default {
  name: "DirectorySearch",
  data: function () {
    return {
      currentPage: 1,
      maxPerPage: 2,
      showReadMore: true,
      businessCategoryIds: [],
      listOfCountries: [],
      listOfStates: [],
      listOfCities: [],
      listOfBusiness: [],
      isLoading: false,
      searchParms: {
        textSearch: "",
        countryId: "",
        stateId: "",
        cityId: "",
        businessCategoryIds: [],
      },
      currentCountry: { countryName: null, id: null },
      currentState: { stateName: null, id: null },
      currentCity: { cityName: null, id: null },
      currentAddress: {
        country: null,
        countryCode: null,
        city: null,
        district: null,
        region: null,
        timezone_name: null,
        latitude: null,
        longitude: null,
      },
    };
  },
  components: {
    NavBar,
    FooterBar,
    LoadingScreen,
    MoonLoader,
    DisplayBusinessOnMap,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("business", ["businessCategories"]),
    ...mapGetters("directorySearch", [
      "businesses",
      "countries",
      "states",
      "cities",
    ]),
  },
  mounted() {
    this.fetchCurrentAddress();
    this.getBusinessCategories();
    this.isLoading = true;
    this.getBusinesses().then(() => {
      this.isLoading = false;

      this.listOfBusiness = Object.keys(this.businesses).map((key) => {
        return this.businesses[key];
      });
    });
    this.getCountries().then(() => {
      if (this.countries) {
        this.listOfCountries = this.countries;
      }
    });
  },
  updated() {
    var $form_search_ajax = $(".form-search-ajax");

    if ($form_search_ajax.length < 1) {
      return;
    }

    var $item = $form_search_ajax.find(".item");
    $item.on("click", function (e) {
      var $link = $(this).find("a");
      if ($link.attr("href") === "#") {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        var $parent = $(this).parents(".dropdown");
        var $input = $parent.find(".form-control");
        var $children = $(this).find("a").find("span");
        var text;
        if ($children.length > 0) {
          text = $children.html();
        } else {
          text = $(this).text();
        }
        var txt = document.createElement("textarea");
        txt.innerHTML = text.trim();
        var new_text = txt.value;
        $input.val(new_text);

        $parent.find(".dropdown-menu").removeClass("show");
      }
    });
  },
  methods: {
    ...mapActions("auth"),
    ...mapActions("business", ["sendBusinessRequest", "getBusinessCategories"]),
    ...mapActions("directorySearch", [
      "getBusinesses",
      "nearByPartners",
      "nearByRelatedPartners",
      "partnerSearch",
      "getCountries",
      "getStates",
      "getCities",
    ]),

    fetchCurrentAddress() {
      var myHeaders = new Headers();
      myHeaders.append(
        "Cookie",
        "__cf_bm=g5jHR_zMgia8pVEUYAMrzQIukTRn1mqzd9V5g3INXzE-1640970434-0-AV57gMcAFn3k+DoUoViOyBC2sfdZz8ZnYQHzy5HtwDPERURBDJTYeYLqPXUr+SaFv1yBe/NEYAHmW+cQa/UAleA="
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch("https://json.geoiplookup.io", requestOptions)
        .then((response) => response.json())
        .then((result) => this.updateCurrentAddress(result))
        .catch((error) => console.log("error", error));
    },
    updateCurrentAddress(response) {
      this.currentAddress = {
        latitude: response.latitude,
        longitude: response.longitude,
        country: response.country_name,
        countryCode: response.country_code,
        city: response.city,
        district: response.district,
        region: response.region,
        timezone_name: response.timezone_name,
      };
    },
    getNearByPartners() {
      this.isLoading = true;
      this.nearByPartners().then(() => {
        this.isLoading = false;

        this.listOfBusiness = Object.keys(this.businesses).map((key) => {
          return this.businesses[key];
        });
      });
    },
    getNearByRelatedPartners() {
      this.isLoading = true;
      this.nearByRelatedPartners().then(() => {
        this.isLoading = false;

        this.listOfBusiness = Object.keys(this.businesses).map((key) => {
          return this.businesses[key];
        });
      });
    },
    filterCountries: function (event) {
      event.preventDefault();
      this.listOfCountries = this.countries;
      let searchText = event.target.value.toUpperCase();
      this.getCountries(searchText).then(() => {
        if (this.countries) {
          this.listOfCountries = this.countries;
          this.listOfStates = [];
        }
      });
    },
    filterStates: function (event) {
      event.preventDefault();
      let searchText = event.target.value.toUpperCase();
      if (this.currentCountry && this.states) {
        this.getStates({
          countryId: this.currentState.id,
          stateName: searchText,
        }).then(() => {
          if (this.states) {
            this.listOfStates = this.states;
            this.listOfCities = [];
          }
        });
      }
    },
    filterCities: function (event) {
      event.preventDefault();
      let searchText = event.target.value.toUpperCase();
      this.getCities({
        stateId: this.searchParms.stateId,
        cityName: searchText,
      }).then(() => {
        if (this.cities) {
          this.listOfCities = this.cities;
        }
      });
    },
    filterAnything: function (event) {
      event.preventDefault();

      let searchText = event.target.value.toLowerCase();
      this.searchParms.textSearch = searchText;
      this.doSearch();
    },
    doSearch() {
      console.log(this.searchParms);
      setTimeout(() => {
        this.isLoading = true;
        this.partnerSearch(this.searchParms).then(() => {
          this.isLoading = false;

          this.listOfBusiness = Object.keys(this.businesses).map((key) => {
            return this.businesses[key];
          });
        });
      }, 400);
    },
    filterByCategories() {
      setTimeout(() => {
        if (this.businessCategoryIds.length > 0) {
          this.searchParms.businessCategoryIds = this.businessCategoryIds;
          this.doSearch();
        }
      }, 500);
    },
    refreshCountries(countryId = 0, countryName) {
      this.listOfCountries = this.countries;
      this.searchParms.countryId = countryId;
      this.currentCountry.countryName = countryName;

           this.currentState.stateName = '';
           this.currentState.cityName = '';
          this.listOfStates = [];
          this.listOfCities = [];

      if (this.searchParms.countryId > 0) {
        this.getStates({
          countryId: this.searchParms.countryId,
          stateName: null,
        }).then(() => {
          this.listOfStates = this.states;
        });

        this.doSearch();
      }
      
    },
    refreshStates(stateId = 0, stateName = "") {

      this.listOfStates = this.states;
      this.searchParms.stateId = stateId;
      this.currentState.stateName = stateName;
       this.currentState.cityName = '';

      if (this.searchParms.stateId > 0) {
        this.getCities({
          stateId: this.searchParms.stateId,
          cityName: null,
        }).then(() => {
          this.listOfCities = this.cities;
        });
      
      this.doSearch();
       
      }
    },
    refreshCities(cityId = 0, cityName = "") {
      this.listOfCities = this.cities;
      this.searchParms.cityId = cityId;
      this.currentCity.cityName = cityName;

      if (this.searchParms.cityId > 0) {
        this.doSearch();
      }
    },

    totalResults() {
      return Object.keys(this.listOfBusiness).length;
    },
    pageCount() {
      return Math.ceil(this.totalResults / this.maxPerPage);
    },
    pageOffest() {
      return this.maxPerPage * this.currentPage;
    },
    paginatedRequests() {
      return this.listOfBusiness.slice(0, this.pageOffest());
    },
    loadMore() {
      this.currentPage += 1;
    },
  },
};
</script>