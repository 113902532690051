<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal" :class="modalSize?modalSize:'modal-md'" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header v-if="!hideHeader" class="modal-header" id="modalTitle">
                    <slot name="header">
                    </slot>
                    <button v-if="!hideCloseBtn" type="button" style="margin-right:15px" class="btn-close" @click="close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                </header>

                <section class="modal-body" id="modalDescription">
                    <slot name="body">
                        This is the default body!
                    </slot>
                </section>

                <footer v-if="!hideFooter" class="modal-footer">
                    <slot name="footer">
                    </slot>
                    
                    <button v-if="!hideCloseBtn" type="button" class="btn float-right" @click="close">
                        Close
                    </button>

                </footer>
            </div>
        </div>
    </transition>
</template>

<!-- components/ConfirmDialogue.vue -->

<script>
    export default {
        name: 'DynamicModal',
        props: ["modalSize",'hideCloseBtn','hideHeader','hideFooter'],
        methods: {
            close() {
                this.$emit('close');
            },
        },
    }
</script>
<!-- components/ConfirmDialogue.vue -->

<style>
    .modal-backdrop {
        position: fixed;
        top: 2;
        bottom: 2;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99999;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }

    .modal-backdrop .modal-full {
        width: 100%;
    }

    .modal-backdrop .modal-md {

        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 500px;
        height: auto;
        
    background-clip: padding-box;
    border: 1px solid #36beb0;
    border-radius: 5px;
    }

    .modal-backdrop .modal-sm {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        height: auto;
        
    background-clip: padding-box;
    border: 1px solid #36beb0;
    border-radius: 5px;
    }

    .modal-backdrop .modal-lg {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 800px;
        height: auto;
        
    background-clip: padding-box;
    border: 1px solid #36beb0;
    border-radius: 5px;
    }

    .modal-backdrop .modal-xl {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1140px;
        height: auto;
        
    background-clip: padding-box;
    border: 1px solid #36beb0;
    border-radius: 5px;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }

    .modal-footer {
        position: relative;
        border-top: 1px solid #eeeeee;
        /* flex-direction: column; */
    }

    .modal-body {
        position: relative;
        padding: 20px 10px;
    }

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }

    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }
</style>