<template>
  <!-- #site-wrapper start -->
  <div v-if="user" id="site-wrapper" class="site-wrapper panel page-invoice-listing">
    <!-- #header start -->
    <!-- #header start -->
    <NavBar />
    <!-- #header end -->
    <!-- #wrapper-content start -->
    <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
      <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap">

        <LeftSideBar />

        <div class="page-container">
          <div class="container-fluid">

            <div class="page-content-wrapper d-flex flex-column h-100">
              <h1 class="font-size-h4 mb-4 font-weight-normal">My Payments</h1>
              <div class="page-content">
                <div class="font-size-lg text-dark font-weight-semibold border-bottom mb-3 pb-2 lh-1">
                  Payment Listings
                </div>
               <Payments  v-bind:partner-id="this.$route.params.partnerId" />
              </div>

            </div>

           

          </div>
          
        </div>
         
      </div>

    </div>
    <FooterBar />
    <!-- #wrapper-content end -->
  </div>


  <div v-else>
    <LoadingScreen />
  </div>


</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import NavBar from "../Layouts/NavBar";
  import LeftSideBar from "../Layouts/LeftSideBar";
  import FooterBar from "../Layouts/FooterBar";
  import LoadingScreen from "../Layouts/LoadingScreen";
  import Payments from "../MyPayment/Payments"
  export default {
    name: "MyPayment",

    components: {
      NavBar,
      LeftSideBar,
      FooterBar,
      Payments,
      LoadingScreen
    },
       computed: {
             ...mapGetters("auth", ["user"])
        },
        mounted() {
        },
        methods: {
            ...mapActions("auth")
    
        },
  };
</script>