<template>
    <div>
        <div class="container" v-if="invoice">
            <div class="row">
                <div class="col-md-2 col-sm-12"></div>
                <div class="col-md-8 col-sm-12">
                    <div class="page-content">
                        <div class="mb-9 row flex-md-nowrap">
                            <div class="col-4 mb-4 mb-md-0">
                                <div class="mb-5">
                                    <img src="images/logo.png" alt="Thedir">
                                </div>
                                <div class="text-dark font-size-md lh-1625">
                                    <address>
                                        Akutari Limited<br>
                                        Kigali,
                                        Rwanda
                                    </address>
                                </div>
                            </div>
                            <div class="col-4 mb-4 mb-md-0">
                                <div class="text-gray font-weight-medium text-uppercase mb-4">
                                    Bill to
                                </div>
                                <div class="text-dark font-size-md lh-1625">
                                    <address>
                                        {{invoice.partner.businessName}}<br>
                                        {{invoice.partnerAddress?invoice.partnerAddress.grideCode:''}},
                                       
                                         {{invoice.partnerAddress.city.cityName}} <br>
                                        {{invoice.partnerAddress.state.stateName}},
                                        {{invoice.partnerAddress.country.countryName}} <br>
                                        {{invoice.partnerAddress.address}}
                                        
                                    </address>
                                </div>
                            </div>
                            <div class="col-4  ml-0 ml-md-auto text-left text-md-right">
                                <div class="text-gray font-weight-medium text-uppercase mb-4">
                                    information invoice
                                </div>
                                <div class="text-dark font-size-md lh-1625">
                                    <div class="">
                                        <span class="text-dark font-weight-semibold">Order:</span>
                                        <span class="text-link"> {{invoice.id}}</span>
                                    </div>
                                    <div class="">
                                        <span class="text-dark font-weight-semibold">Issued:</span>
                                        <span> {{invoice.issuedDate}} </span>
                                    </div>
                                   

                                </div>
                            </div>
                        </div>
                        <div class="table-responsive-md">
                            <table class="table" v-if="invoice">
                                <thead>
                                    <tr>
                                        <th style="width:34%">Items &amp; description</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th class="text-right">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        
                                        <td><span v-if="invoice.subscription" class="text-primary">{{invoice.subscription.title}} 
                                        </span> <p class="font-size-sm font-weight-semibold">
                                            {{invoice.subscription.description}}
                                            </p></td>
                                        <td>{{invoice.partnerSubscription.subscriptionQuantity}} {{invoice.partnerSubscription.subscriptionPeriod}}</td>
                                        <td>{{invoice.subscription.price}} {{invoice.currency}}</td>
                                        <td class="text-right font-weight-semibold">{{invoice.amount}}{{invoice.currency}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex mt-4">
                            <div class="ml-auto text-right">
                                <span class="text-dark font-size-lg d-block font-weight-semibold total">Invoice Total:
                                    {{invoice.amount}}{{invoice.currency}}
                                    </span>
                                <span class="font-size-md">Paid via {{invoice.paymentMethod}}</span>
                            </div>
                        </div>
                        <!-- <div class="contact-info d-flex flex-wrap flex-md-nowrap font-size-md mt-13">
                            <a href="mailto:www.example.com" class="link-hover-dark-primary">www.example.com</a>
                            <span class="separate text-dark">|</span>
                            <a href="mailto:office@example.com" class="link-hover-dark-primary">office@example.com </a>
                            <span class="separate text-dark">|</span>
                            <span class="text-dark">(123) 123-456</span>
                        </div> -->
                    </div>
                </div>

                <div class="col-md-4 col-sm-12"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Invoice",
        props: ["invoice"],
        data: function () {
            return {}
        },
        mounted() {


        },
        computed: {},

        methods: {

        },
    };
</script>