<template>
    <div>
      <div
      v-if="isLoading"
      class="card-deck justify-content-center align-items-center"
    >
      <MoonLoader
        :loading="true"
        :color="'#36beb0'"
        :size="60"
        :sizeUnit="'px'"
      />
    </div>

  <div class="table-responsive-md" v-else>
      <table class="table" style="width: 100%" v-if="payments.length > 0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Amount</th>
                        <th>Method</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="payment in payments" :key="payment.id">
                        <td><a href="#" class="link-hover-dark-blue">{{payment.id}}</a></td>
                        <td>{{payment.currency}} {{payment.amount}}</td>
                        <td><span class="status paid" style="color:#253644">{{payment.paymentMethod}}</span></td>
                        <td>
                            <span v-if="payment.paymentStatus=='successful'" class="status paid"
                                style="color:#36beb0">{{payment.paymentStatus}}</span>
                            <span v-if="payment.paymentStatus=='failed'" class="status paid"
                                style="color:#EC4B1A">{{payment.paymentStatus}}</span>
                            <span v-if="payment.paymentStatus=='pending'" class="status paid"
                                style="color:#EEDF0D">{{payment.paymentStatus}}</span>
                        </td>
                        <td>
                            <button @click="showInvoiceModal(payment)" class="btn btn-default">View Invoice</button>
                        </td>
                    </tr>

                </tbody>
            </table>
            <div class="photo-gallery d-flex flex-wrap justify-content-between">
        <NoResult
          v-if="payments.length == 0"
          title="Ohh! payments not found"
          message="It seems we can’t find what you’re looking for."
        ></NoResult>
      </div>

        <DynamicModal v-show="isInvoiceModalVisible && invoice" @close="closeModal" v-bind:modal-size="'modal-full'">
                <template v-slot:header>
                    Detail Invoice
                </template>

                <template v-slot:body>
                    <Invoice v-bind:invoice="invoice"/>
                </template>

                <template v-slot:footer>
                </template>
        </DynamicModal>
        </div>


        

    </div>
</template>

<script>
    import {
        mapGetters,mapActions
    } from "vuex";
    import Invoice from "./Invoice"
    import DynamicModal from "../Common/Modal/DynamicModal";
    import { MoonLoader } from "@saeris/vue-spinners";
    import NoResult from "../Common/Message/NoResult";
    export default {
        name: "Payments",
        props: ["partnerId"],
        data: function () {
            return {
                isLoading: true,
                invoice: null,
                isInvoiceModalVisible: false
            }
        },
         components: {
             Invoice,
             DynamicModal,
              MoonLoader,
              NoResult
         },
        mounted() {
            this.getPayments(this.partnerId).then(()=>{
            this.isLoading=false
        });
        },
        computed: {
            ...mapGetters("payment", ['payments'])
        },

        methods: {
             ...mapActions("payment", ["getPayments"]),
            showInvoiceModal(invoice) {

                this.invoice=invoice;
                this.isInvoiceModalVisible = true;
            },
            closeModal() {
                 this.invoice=null;
                this.isInvoiceModalVisible = false;
            },
        },
    };
</script>